<template>
	<v-sheet flat>
		<template v-if="logged_in">
			<template v-if="canAccess">
				<room v-if="start" :roomName="roomName"></room>

				<v-card v-else color="lightest" class="rounded-xl" flat>
					<v-card-text>
						<p>
							You're about to enter you team video room. If you have any
							concerns or questions, please get in touch with us using the live
							chat (bottom left).
						</p>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn @click="start = true" color="primary">Enter</v-btn>
					</v-card-actions>
				</v-card>
			</template>
			<v-card v-else color="lightest" class="rounded-xl" flat
				><v-card-text
					>Please wait to be admitted to the video conference</v-card-text
				></v-card
			>
		</template>
		<template v-else>
			<v-card color="lightest" class="rounded-xl" flat
				><v-card-text
					>Please <a @click="$root.$emit('openLogin')">log in</a></v-card-text
				></v-card
			>
		</template>
	</v-sheet>
</template>

<script>
	import Room from "@c/video/Room.vue";
	export default {
		name: "VideoContainer",
		props: {
			id: {type: String}
		},
		data: () => {
			return {
				start: false
			};
		},
		components: {Room},
		computed: {
			auth() {
				return this.$store.state.auth.data;
			},
			logged_in() {
				return this.auth.id || false;
			},
			videoRoom() {
				return this.$store.state.videoRoom.data;
			},
			roomName() {
				return this.videoRoom.id;
			},
			canAccess() {
				return this.$store.getters["videoRoom/canAccess"]();
			}
		},
		watch: {
			auth: {
				immediate: true,
				handler(value) {
					if (value) {
						this.$store.dispatch("videoRoom/start", this.id);
					}
				}
			}
		},
		destroyed() {
			this.$store.dispatch("videoRoom/leaveConference");
		}
	};
</script>
