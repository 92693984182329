<template>
	<v-card 
		flat
		min-height="180"
		color="lightest"
		class="participant">

		<div v-if="haveVideo">
			<video-track

				size="preview"
				@select="select"
				v-for="track in videoTracks"
				:track="track"
				:key="track.sid"
			></video-track>
		</div>
		<participant-avatar
			v-else
			v-model="localIdentity"
		></participant-avatar>
		<slot/>
	</v-card>
</template>

<style lang="less"></style>

<script>
import ParticipantAvatar from "@c/video/ParticipantAvatar";
import VideoTrack from "@c/video/VideoTrack";
export default {
	name: "LocalPreview",
	data: () => {
		return {
		};
	},
	computed: {
		videoRoom(){
			return this.$store.state.videoRoom.data;
		},
		localIdentity() {
			return this.$store.state.auth.data.id || "You";
		},

		videoTracks(){
            const self = this;
            let tracks = self.$store.getters["videoRoom/localVideos"];
			tracks = tracks.filter( a => !self.isMain(a) ); 
			return tracks;
		},
		haveVideo() {
			return this.videoTracks.length > 0;
		},
		mainTrack(){
			return this.$store.state.videoRoom.main_track || false;
		}
	},
	methods: {
		select(track) {
            this.$root.$emit("selectMainTrack", {
				track,
				identity: this.localIdentity,
			});
		},
        isMain(track){
            if( !this.mainTrack ){
                return false;
            }
            if( !this.mainTrack.identity == this.localIdentity ){
                return false;
            }
            return track == this.mainTrack.track;
        }
	},
	components: {
		ParticipantAvatar,
		VideoTrack,
	},
};
</script>
